import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Skeleton",
  code: "Skeleton"
};
export const _frontmatter = {
  "menuLabel": "Skeleton",
  "sortOrder": 19.2
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Skeleton`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-skeleton--default-story" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Skeleton } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`Skeleton`}</inlineCode>{` component is a simple composable placeholder element for constructing Skeleton layouts. `}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
  <AccessibilityTable.Principle principle="Percievable" status="ready">
    The Skeleton component provides a visually distinguishable animated box element that serves as a placeholder in skeleton layouts. It utilizes appropriate sizing and border radius to create a recognizable placeholder shape. The component is designed to be noticeable and draw attention to the layout structure.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Operable" status="ready">
    The Skeleton component itself is not directly operable, as it is a visual placeholder rather than an interactive element. However, it should not interfere with the operability of other elements on the page, allowing users to interact with the available content while the skeleton layout is displayed.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Understandable" status="ready">
    The purpose of the Skeleton component is clear, providing a placeholder element for constructing skeleton layouts. The width and height properties allow customization of the size of the skeleton element, ensuring it aligns with the intended layout structure. The rounded property controls the border radius, allowing variations in the appearance of the skeleton element.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Robust" status="ready">
    The Skeleton component is designed to be compatible with assistive technologies, ensuring that screen readers can understand and convey the presence of the skeleton layout. It functions consistently across different browsers and platforms. The component can be easily integrated into various parts of the application to create consistent skeleton layouts.
  </AccessibilityTable.Principle>
    </AccessibilityTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      